<template>
  <article class="disclosure whistleblower">
    <h1>Notre système de dénonciation</h1>
    <p>
      La conformité aux règlements statutaires et aux règles internes, ainsi qu’aux principes énoncés dans notre Code de
      conduite et le Code de conduite des partenaires commerciaux, est une priorité absolue pour Electrify Canada. Le
      succès de notre entreprise repose sur l’intégrité et la conformité. Pour respecter ces normes, il est important
      pour nous d’être au courant de l’inconduite potentielle d’un employé ou d’un fournisseur et d’y mettre fin. Par
      conséquent, nous confions au Bureau central des enquêtes du groupe Volkswagen (« Bureau d’enquête ») le soin
      d’exploiter en notre nom un système de dénonciation indépendant, impartial et confidentiel.
    </p>
    <p>
      L’un des principaux piliers de notre système de dénonciation est le principe de l’équité procédurale. Elle
      garantit également la plus grande protection possible aux dénonciateurs, aux personnes en cause et aux employés
      contribuant aux enquêtes sur les cas d’inconduite signalés.
    </p>
    <p>
      Cela comprend également la possibilité d’effectuer des signalements et des communications anonymes. Nous ne
      prendrons aucune mesure qui permettrait d’identifier les dénonciateurs anonymes qui n’abusent pas de notre système
      de dénonciation. Les représailles contre les dénonciateurs et toutes les personnes qui contribuent aux enquêtes
      d’Electrify Canada ne seront pas tolérées. Les personnes en cause sont présumées innocentes jusqu’à ce que
      l’infraction soit prouvée. Les enquêtes seront menées dans la plus grande confidentialité. Les renseignements
      seront traités dans le cadre d’un processus équitable, rapide et protégé.
    </p>
    <h2 class="headline4">Comment traitons-nous votre signalement?</h2>
    <p>
      Les collègues qualifiés et expérimentés du Bureau des enquêtes examinent chaque signalement d’inconduite
      potentielle concernant un employé d’Electrify America de manière approfondie, et en assurent le suivi
      systématique. Tout d’abord, vous recevrez une confirmation de réception. Le Bureau des enquêtes évalue ensuite
      votre signalement. Il s’agit notamment de recueillir les faits auprès du dénonciateur. Si l’évaluation initiale
      indique qu’il y a lieu de soupçonner une infraction, une enquête sera ouverte par une unité d’enquête spécialisée.
      Par la suite, les résultats de l’enquête seront évalués par le Bureau des enquêtes, et des mesures seront
      recommandées. L’information sur l’état* et les résultats de la procédure vous seront fournis sans retard
      injustifié.
    </p>
    <p>
      Les violations potentielles du Code de conduite des partenaires commerciaux par les fournisseurs, y compris les
      risques graves et les violations des droits de la personne et de l’environnement par les fournisseurs directs et
      indirects, peuvent également être signalées au Bureau des enquêtes, ainsi que les signalements qui nécessitent des
      mesures immédiates. Le Bureau d’enquête informera les services responsables, qui traiteront la question en
      conséquence. Il s’agit notamment de prendre les mesures nécessaires pour réduire au minimum ou mettre fin aux
      violations et/ou aux risques.
    </p>
    <p>* La durée de traitement varie selon l’objet de la procédure.</p>
    <p>
      Vous pouvez en apprendre davantage sur les principes procéduraux de notre système de dénonciation
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://www.volkswagenag.com/presence/konzern/compliance-und-risikomanagement/compliance/Volkswagen_Group_Complaints_Procedure.pdf#page=24"
        ><strong>ici.</strong>
      </a>
    </p>
    <h2 class="headline4">
      Avez-vous des préoccupations ou des commentaires concernant un produit ou un service d’Electrify Canada?
    </h2>
    <p>
      Si vous avez des questions ou des commentaires concernant les services offerts par Electrify Canada, veuillez
      envoyer un courriel à
      <a href="mailto:support@electrify-canada.ca" class="link"><strong> support@electrify-canada.ca.</strong></a>
      Nous vous demandons de bien vouloir comprendre que le système de dénonciation ne peut malheureusement pas traiter
      les plaintes des clients.
    </p>
    <h2 class="headline4">Effectuer un signalement à notre système de dénonciation</h2>
    <h2 class="headline4 light">Bureau central des enquêtes du groupe Volkswagen</h2>
    <p><strong>Service d’assistance téléphonique pour les dénonciateurs :</strong></p>
    <p>
      833 657-1574 (sans frais)<br />
      908 219-8092 (local)<br />
      En ligne :
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=22vwgroup16&language=eng"
        ><strong>Service de communication protégée</strong></a
      >
      <br />Courriel : <a href="mailto:io@Volkswagen.de" class="link"><strong>Bureau des enquêtes</strong></a>
    </p>
    <p>
      <strong>Adresse postale :</strong><br />Volkswagen AG, Bureau central des enquêtes<br />Boîte aux lettres 1717<br />Berliner
      Ring 2<br />38436 Wolfsburg, Allemagne <br /><strong>En personne :</strong><br />Veuillez envoyer un courriel à
      <a href="mailto:io@Volkswagen.de" class="link"><strong>io@volkswagen.de</strong></a> pour prendre rendez-vous.
    </p>
    <h2 class="headline4">Médiateurs</h2>
    <p>
      Outre le Bureau central des enquêtes, deux avocats externes (protecteurs) jouent le rôle de médiateurs neutres.
      Les médiateurs externes nommés par le Groupe Volkswagen agissent en tant qu’avocats de confiance et reçoivent des
      indications concernant d’éventuelles infractions au règlement et vérifient si elles sont plausibles et fondées. En
      fin de compte, ils transmettent au Bureau central des enquêtes toutes les informations dont il a été question avec
      le dénonciateur pour un traitement ultérieur.
    </p>
    <p>
      Si vous souhaitez communiquer avec le médiateur ou la médiatrice, vous pouvez trouver les coordonnées ici :
      <a class="link" rel="noopener" target="_blank" href="https://www.ombudsmen-of-volkswagen.com/"
        ><strong>https://www.ombudsmen-of-volkswagen.com/</strong></a
      >
    </p>
    <p><strong>Groupe Volkswagen d’Amérique</strong></p>
    <p>
      <strong>Service d’assistance téléphonique pour les questions d’éthique :</strong> 888 228-7317<br /><strong
        >En ligne :
      </strong>
      <a
        class="link"
        rel="noopener"
        target="_blank"
        href="https://secure.ethicspoint.com/domain/media/en/gui/66470/index.html"
        ><strong>EthicsPoint</strong></a
      >
    </p>
  </article>
</template>

<script>
export default {
  metaInfo: {
    title: `Système de dénonciation| Electrify Canada`,
    meta: [
      {
        name: 'description',
        content: `Apprenez-en davantage sur notre système de dénonciation et sur la façon de signaler anonymement une violation d'un règlement ou du code de conduite.`,
      },
    ],
  },
};
</script>
